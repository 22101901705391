<template>
  <div class='w-full'>
    <div class='flex flex-row justify-start items-center gap-x-1 p-2'>
      <ipo-search class='flex-grow h-full' />
    </div>
    <div 
      class='flex flex-col-reverse justify-start items-stretch relative overflow-x-auto gap-y-4 px-2'>
      <ipo-allocated-information v-if='hasSelectedIpo' />
      <div v-else
        class='bg-gray-200 rounded p-16 text-center w-full text-sm uppercase'>
        Please select an IPO above.
      </div>
      <ipo-allocated-summaries v-if='hasSelectedIpo' />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IpoSearch               from '@/components/IpoSearch.vue'
import IpoAllocatedInformation from '@/components/mobile/IpoAllocatedInformation.vue'
import IpoAllocatedSummaries   from '@/components/mobile/IpoAllocatedSummaries.vue'

export default {
  name: 'IpoAllocated',
  components: {
    IpoSearch,
    IpoAllocatedInformation,
    IpoAllocatedSummaries,
  },
  computed: {
    ...mapGetters('ipos', [
      'hasSelectedIpo',
    ]),
  },
  methods: {
    ...mapActions('ipos', [
      'getIpos',
    ]),
    ...mapActions('brokers', [
      'getBrokers',
    ]),
    refreshData () {
      this.getIpos()
      this.getBrokers()
    },
  },
  mounted () {
    this.refreshData()
  }
}
</script>
