<template>
    <v-select
      v-model='ipo'
      label='name'
      @input='updateSelectedIpoId'
      class='w-64 text-sm'
      :close-on-select='true'
      :options='ipos'
      placeholder='IPO 종목 선택'>
      <template v-slot:option='option'>
        <span>{{ option.name }}</span>
      </template>
      <template v-slot:no-options>
        <div class='py-3 text-gray-500 text-center'>IPO 종목</div>
      </template>
    </v-select>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'IpoSearch',
  data () {
    return {
      ipo: null,
    }
  },
  watch: {
    selectedIpoId: {
      handler: function () {
        if (this.selectedIpoId > 0) {
          this.getIpoSummaries()
          this.getHistoricalIpo()
          this.updateSelectedIpo().then(() => {
            this.ipo = this.selectedIpo
          })
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('ipos', [
      'ipos',
      'ipoSummaries',
      'selectedIpo',
    ]),
    ...mapFields('ipos', [
      'selectedIpoId',
    ]),
  },
  methods: {
    ...mapActions('ipos', [
      'getIpoSummaries',
      'getHistoricalIpo',
      'updateSelectedIpo',
    ]),
    updateSelectedIpoId () {
      if (this.ipo) {
        this.selectedIpoId = this.ipo.id
      } else {
        this.selectedIpoId = null
      }
    },
  },
}
</script>
