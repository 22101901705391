<template>
  <div class='w-full'>
    <label>하이일드 포함
      <input type='checkbox'
        class='form-checkbox'
        v-model='isHighYeildVisible'
        @click='toggleHighYeild'>
    </label>
    <table
      class='text-xs'>
      <thead>
        <tr>
          <th class='text-left'>구분</th>
          <th class='text-left'>확약여부</th>
          <th class='text-right'>배정수량</th>
          <th class='text-right'>배정금액</th>
          <th class='text-right'>배정비중</th>
          <th class='text-left'>기준</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class='text-left pl-2 font-bold border-t border-b'>펀드전체</td>
          <td class='border-t border-b font-bold pl-2 text-left'></td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ numberStyle(allocatedQuantity) }} <span class='text-xs opacity-60'>주</span></td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ numberStyle(allocatedAmount) }} <span class='text-xs opacity-60'>백만원</span></td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ allocatedRate }}<span class='text-xs opacity-60'>%</span></td>
          <td class='border-t border-b font-bold pl-2 text-left'>기관배정 주식수 대비</td>
        </tr>
        <ipo-allocated-summary-row v-for='summary in filteredSummaries' 
          :key='summary.id' 
          :summary='summary' />
        <tr>
          <td class='text-left pl-2 font-bold border-t border-b'>고유</td>
          <td class='border-t border-b pl-2 text-left'> {{ selectedProprietaryQuantityPeriod }}</td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ numberStyle(proprietaryQuantity) }}<span class='text-xs opacity-60'>주</span></td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ numberStyle(proprietaryAmount) }} <span class='text-xs opacity-60'>백만원</span></td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ proprietartAllocatedRate }} <span class='text-xs opacity-60'>%</span></td>
          <td class='border-t border-b font-bold pl-2 text-left'>기관배정 주식수 대비</td>
        </tr>
        <tr>
          <td class='text-left pl-2 font-bold border-t border-b'>캐피탈 </td>
          <td class='border-t border-b pl-2 text-left'> {{ selectedCapitalQuantityPeriod }}</td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ numberStyle(capitalQuantity) }}<span class='text-xs opacity-60'>주</span></td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ numberStyle(capitalAmount) }} <span class='text-xs opacity-60'>백만원</span></td>
          <td class='border-t border-b font-bold pl-2 text-right'>{{ capitalAllocatedRate }} <span class='text-xs opacity-60'>%</span></td>
          <td class='border-t border-b font-bold pl-2 text-left'>기관배정 주식수 대비</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import IpoAllocatedSummaryRow from '@/views/ipo/IpoAllocatedSummaryRow.vue'

export default {
  name: 'IpoAllocatedSummaries',
  components: {
    IpoAllocatedSummaryRow,
  },
  data () {
    return {
      isHighYeildVisible: false,
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
    ]),
    ...mapMultiRowFields('ipos', [
      'ipoSummaries',
    ]),
    ...mapGetters('users', [
      'currentIpoDetailsName',
    ]),
    filteredData () {
      return this.ipoSummaries.filter(summary => summary.account_type !== '고유')
    },
    filteredSummaries () {
      return this.isHighYeildVisible ? this.filteredData : this.filteredData.filter(summary => summary.account_type !== '하이일드')
    },
    allocatedQuantity () {
      if (!this.ipoSummaries)
        return 0

      return this.ipoSummaries.reduce((sum, summary) => sum + (summary.account_type === '고유' ? 0 : summary.allocated_quantity), 0)
    },
    allocatedAmount () {
      if (!this.ipoSummaries)
        return 0

      return ((this.ipoSummaries.reduce((sum, summary) => sum + ((summary.account_type === '고유' ? 0 : summary.allocated_quantity) * summary.allocated_price), 0)) / 1000000).toFixed(0)
    },
    allocatedRate () {
      if (!this.selectedIpo || !this.selectedIpo.details || !this.selectedIpo.details['기관배정주식수'] || this.selectedIpo.details['기관배정주식수'] === 0)
        return 0.0

      return (this.allocatedQuantity / this.selectedIpo.details['기관배정주식수'] * 100).toFixed(1)
    },
    allocatedPrice () {
      return this.selectedIpo.details && this.selectedIpo.details['확정공모가'] ? this.selectedIpo.details['확정공모가'] : 0
    },
    baseAllocatedQuantity () {
      if (!this.ipoSummaries)
        return 0

      return this.selectedIpo.details['기관배정주식수'] ? this.selectedIpo.details['기관배정주식수'] : 0
    },
    proprietaryQuantity () {
      return this.selectedIpo.details &&
            this.selectedIpo.details[this.currentIpoDetailsName] &&
            this.selectedIpo.details[this.currentIpoDetailsName]['고유배정주식수'] ? this.selectedIpo.details[this.currentIpoDetailsName]['고유배정주식수'] : 0
    },
    capitalQuantity () {
      return this.selectedIpo.details &&
            this.selectedIpo.details[this.currentIpoDetailsName] &&
            this.selectedIpo.details[this.currentIpoDetailsName]['캐피탈배정주식수'] ? this.selectedIpo.details[this.currentIpoDetailsName]['캐피탈배정주식수'] : 0
    },
    proprietaryAmount () {
      return (this.proprietaryQuantity * this.allocatedPrice / 1000000).toFixed(0)
    },
    capitalAmount () {
      return (this.capitalQuantity * this.allocatedPrice / 1000000).toFixed(0)
    },
    proprietartAllocatedRate () {
      if (!this.selectedIpo || this.proprietaryQuantity === 0)
        return 0.0

      return ((this.proprietaryQuantity / this.baseAllocatedQuantity) * 100).toFixed(1)
    },
    capitalAllocatedRate () {
      if (!this.selectedIpo || this.proprietaryQuantity === 0)
        return 0.0

      return ((this.proprietaryQuantity / this.baseAllocatedQuantity) * 100).toFixed(1)
    },
    selectedProprietaryQuantityPeriod () {
      return this.selectedIpo.details &&
              this.selectedIpo.details[this.currentIpoDetailsName] &&
              this.selectedIpo.details[this.currentIpoDetailsName]['고유확약여부'] ? this.selectedIpo.details[this.currentIpoDetailsName]['고유확약여부'] : ''
    },
    selectedCapitalQuantityPeriod () {
      return this.selectedIpo.details &&
              this.selectedIpo.details[this.currentIpoDetailsName] &&
              this.selectedIpo.details[this.currentIpoDetailsName]['캐피탈확약여부'] ? this.selectedIpo.details[this.currentIpoDetailsName]['캐피탈확약여부'] : ''
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    toggleHighYeild () {
      this.isHighYeildVisible = !this.isHighYeildVisible
    },
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply py-2 pl-1 pr-1 border text-gray-700 whitespace-no-wrap bg-gray-200;
  }

  td {
    @apply py-2 pl-1 pr-1 border text-gray-700 whitespace-no-wrap;
  }
</style>
