<template>
  <tr>
    <td :class='classAlign'>{{ summary.account_type }}</td>
    <td :class='classBorder' class='text-left'>{{ summary.lock_up_period }}</td>
    <td :class='classBorder' class='text-right'>{{ numberStyle(summary.allocated_quantity) }}<span class='text-xs opacity-60'>주</span></td>
    <td :class='classBorder' class='text-right'>{{ numberStyle(allocatedAmount) }} <span class='text-xs opacity-60'>백만원</span></td>
    <td :class='classBorder' class='text-right'>{{ allocatedRate }} <span class='text-xs opacity-60'>%</span></td>
    <td :class='classBorder' class='text-left'>{{ baseName }}</td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IpoSummaryRow',
  props: [
    'summary'
  ],
  data () {
    return {
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
      'selectedBrokerId',
    ]),
    allocatedAmount () {
      if (!this.summary)
        return 0

      return ((this.summary.allocated_quantity * this.summary.allocated_price) / 1000000).toFixed(0)
    },
    baseAllocatedQuantity () {
      if (!this.summary)
        return 0

      if (this.summary.account_type === '집합')
        return this.selectedIpo.details['기관배정주식수'] ? parseFloat(this.selectedIpo.details['기관배정주식수']) : 0

      if (this.summary.account_type === '벤처')
        return this.selectedIpo.details['총공모주식수'] ? parseFloat(this.selectedIpo.details['총공모주식수']) * 0.3 : 0

      if (this.summary.account_type === '하이일드')
        return this.selectedIpo.details['총공모주식수'] ? parseFloat(this.selectedIpo.details['총공모주식수']) * 0.05 : 0

      if (this.summary.account_type === '고유')
        return this.selectedIpo.details['기관배정주식수'] ? this.selectedIpo.details['기관배정주식수'] : 0

      return 0
    },
    allocatedRate () {
      if (!this.summary || this.baseAllocatedQuantity === 0)
        return 0.0

      return ((this.summary.allocated_quantity / this.baseAllocatedQuantity) * 100).toFixed(1)
    },
    baseName () {
      if (this.summary.account_type === '집합')
        return '기관배정 주식수 대비'

      if (this.summary.account_type === '벤처')
        return '벤처펀드 해당분 대비'

      if (this.summary.account_type === '하이일드')
        return '하이일드 해당분 대비'

      if (this.summary.account_type === '고유')
        return '기관배정 주식수 대비'

      return '기준 미지정'
    },
    classAlign () {
      return this.summary.account_type === '고유' ? 'text-left pl-2 font-bold border-t border-b' : 'text-center pl-6'
    },
    classBorder () {
      return this.summary.account_type === '고유' ? 'border-t border-b font-bold pl-2' : 'pl-2'
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  }
}
</script>

<style lang='scss' scoped>
  td {
    @apply py-3 pr-2 border-l border-r text-gray-700 whitespace-no-wrap;
  }
</style>
